let windowScrollPosition = 0;
const header = document.querySelector('.header');

const hideHeader = () => {
  const { pageYOffset } = window;

  pageYOffset > windowScrollPosition ?
    header.classList.add('hide') : header.classList.remove('hide');

  windowScrollPosition = pageYOffset;
};

const headerBackground = () => {
  if (header && window.pageYOffset > 10) {
    header.classList.add('bg-black');
    hideHeader();
  } else {
    header.classList.remove('bg-black');
    header.classList.remove('hide');
  }
};

export {
  headerBackground
};
