import { fadeIn, fadeOut } from '../utils/fade';

export default class Tabs {
  constructor(options = {}) {
    if (options.container) {
      this.container = options.container && document.querySelectorAll(options.container) || null;
      this.tabBtnSelector = options.tabBtnSelector || null;
      this.tabContentSelector = options.tabContentSelector || null;
      this.tabContentContainerSelector = options.tabContentContainerSelector || null;
      this.activeClass = options.activeClass || 'active';
      this.duration = options.duration || 'active';
      this.activeIndex = options.activeIndex || 0;
      this.on = {
        beforeInit: options.on && options.on.beforeInit ? options.on.beforeInit.bind(this.on) : null,
        init: options.on && options.on.init ? options.on.init.bind(this.on) : null,
        beforeTabChange: options.on && options.on.beforeTabChange ? options.on.beforeTabChange.bind(this.on) : null,
        afterTabChange: options.on && options.on.afterTabChange ? options.on.afterTabChange.bind(this.on) : null
      };
    }

    if (this.container && this.container.length) {
      this.init();
    }
  }

  init = () => {
    const { container, tabContentContainerSelector, tabContentSelector, activeIndex, duration, handleClick } = this;
    this.on.beforeInit && this.on.beforeInit(this);
    container.forEach(containerEl => {
      containerEl.addEventListener('click', handleClick);
      const tabContentContainer = containerEl.querySelector(tabContentContainerSelector);
      const tabContentElements = containerEl.querySelectorAll(tabContentSelector);
      tabContentContainer.style.height = `${tabContentElements[+activeIndex < tabContentElements.length ? +activeIndex : tabContentElements.length - 1].getBoundingClientRect().height}px`;
      tabContentContainer.style.transitionProperty = `height`;
      tabContentContainer.style.transitionDuration = duration + `ms`;
    });
    this.on.init && this.on.init(this);
  };

  handleClick = e => {
    const { target, currentTarget } = e;
    const {
      tabBtnSelector,
      tabContentSelector,
      tabContentContainerSelector,
      activeClass,
      activeIndex,
      duration,
      disableEvents
    } = this;
    const targetBtn = target.classList.contains(tabBtnSelector.replace(/\./, '')) ? target : target.closest(tabBtnSelector);
    const targetBtnIndex = targetBtn && targetBtn.dataset.tabsIndex;
    let tabContentElement = null;

    const tabContentContainer = currentTarget.querySelector(tabContentContainerSelector);
    const tabBtnElements = currentTarget.querySelectorAll(tabBtnSelector);
    const tabContentElements = currentTarget.querySelectorAll(tabContentSelector);


    disableEvents(currentTarget);
    if (targetBtn && +activeIndex !== +targetBtnIndex) {
      this.activeIndex = targetBtn.dataset.tabsIndex;

      tabContentElements.forEach((tabContent, i) => {
        fadeOut(tabContent, duration);
        tabContent.classList.remove(activeClass);
        if (tabContent.dataset.tabsIndex === targetBtnIndex) {
          tabContentElement = tabContent;
          this.on.beforeTabChange && this.on.beforeTabChange(this, targetBtn, tabContentElement);
        }
      });

      setTimeout(() => {
        tabBtnElements.forEach(btn => btn.classList.remove(activeClass));
        targetBtn && targetBtn.classList.add(activeClass);
        if (tabContentElement) {
          fadeIn(tabContentElement, duration);
          tabContentContainer.style.height = `${tabContentElement.getBoundingClientRect().height}px`;
          this.on.afterTabChange && this.on.afterTabChange(this, targetBtn, tabContentElement);
        }
      }, duration);
    }
  };

  disableEvents = container => {
    container.style.pointerEvents = 'none';
    setTimeout(() => {
      container.style.pointerEvents = 'all';
    }, this.duration);
  };
}
