const anchorScrollHandler = e => {
  const headerHeight = document.querySelector('.header').getBoundingClientRect().height;
  const targetId = e.currentTarget.getAttribute('href').replace(/#/gm, '');
  const targetSection = targetId ? document.querySelector(`#${targetId}`) : null;
  if (targetSection) {
    e.preventDefault();
    const targetSectionRect = targetSection.getBoundingClientRect();
    window.scrollTo({
      top: targetSectionRect.top,
      // top: targetSectionRect.top - headerHeight,
    });
  }
}

export default anchorScrollHandler;

