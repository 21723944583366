import Tabs from './modules/tabs';

const tabsInstance = () => {
  new Tabs({
    container: '.tabs',
    tabBtnSelector: '.tabs__header-btn',
    tabContentSelector: '.tabs__content-item',
    tabContentContainerSelector: '.tabs__content',
    activeClass: 'active',
    activeIndex: 0,
    duration: 100,
    on: {
      init: () => {
        window.addEventListener('resize', () => {
          document.querySelectorAll('.tabs__content').forEach(el => {
            el.removeAttribute('style');
          });
        });
      }
    }
  });
};

export default tabsInstance;
