const headerRef = document.querySelector('.header');
const footerRef = document.querySelector('.footer');



const calcScroll = () => {
  let div = document.createElement('div');
  div.style.visibility = 'hidden';
  div.style.width = '50px';
  div.style.height = '50px';
  div.style.overflowY = 'scroll';
  document.body.appendChild(div);
  let scrollWidth = div.offsetWidth - div.clientWidth;
  div.remove();
  return scrollWidth;
}

const setVhProp = () => {
  const headerHeight = headerRef.getBoundingClientRect().height;
  const footerHeight = footerRef.getBoundingClientRect().height;
  document.documentElement.style.setProperty('--header-height', ''.concat(headerHeight, 'px'));
  document.documentElement.style.setProperty('--footer-height', ''.concat(footerHeight, 'px'));
  document.documentElement.style.setProperty('--window-width', ''.concat(window.innerWidth, 'px'));
};

const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    rect: rect,
    dom: {
      bottom: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    }
  };
};

const getNumbersFromString = string => {
  return string.replace(/D/g, '');
};

const createEl = (element, ...className) => {
  const newElement = document.createElement(element);
  if (!className) return newElement;

  newElement.classList.add(...className);

  return newElement;
};

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}

export {
  getOffset,
  getNumbersFromString,
  setVhProp,
  createEl,
  getRndInteger,
  calcScroll
};
