const animation = (selector, showClass, coefficient, margin) => {
  const targets = document.querySelectorAll(selector);

  const observerHandle = (entries) =>
    entries.forEach(
      ({ isIntersecting, target }) =>
        isIntersecting && !target.classList.contains(showClass) && target.classList.add(showClass)
    );

  const observerInstance = new IntersectionObserver(observerHandle, {
    rootMargin: margin || '0px',
    threshold: coefficient || 0.025
  });
  targets.forEach(target => observerInstance.observe(target));
};

export {
  animation
};
