// Polyfills
import elements from 'aos/src/js/helpers/elements';

require('intersection-observer');

// Imports
import swiperInstance from './swiper-instance';
import tabsInstance from './tabs-instance';
import { headerBackground } from './header-handlers';
import SimpleBar from 'simplebar';
import anchorScrollHandler from './utils/smooth-scroll';
import { formHandler } from './input-validation';
import { calcScroll, getOffset, setVhProp } from './utils/helpers';
import { animation } from './animation';
import { initMap } from './leaflet-instance';

window.addEventListener('load', () => {
  // cookies
  const getCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  const cookiesContainer = document.querySelector('.js-cookies');
  const cookiesClose = cookiesContainer && cookiesContainer.querySelector('[data-accept-cookies]');
  const currentPage = window.location.pathname;
  const isFreePages = currentPage === '/cookie-policy.html' || currentPage === '/privacy-policy.html';
  const cookies_submitted = getCookie('cookies_submitted');

  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  if ((cookiesContainer && cookies_submitted) || isFreePages) {
    document.body.classList.remove('body-lock');
    document.body.style.paddingRight = 0;
    document.querySelector('.header').style.paddingRight = 0;
    cookiesContainer.classList.add('hidde');
  } else {
    document.body.classList.add('body-lock');
    cookiesContainer.classList.remove('hidde');
    document.body.style.paddingRight = `${calcScroll()}px`;
    document.querySelector('.header').style.paddingRight = `${calcScroll()}px`;
  }

  if (cookiesContainer && !cookies_submitted) {
    cookiesClose.addEventListener('click', () => {
      setCookie('cookies_submitted', true, 0);
      cookiesContainer.classList.add('hidde');
      document.body.classList.remove('body-lock');
      document.body.style.paddingRight = 0;
      document.querySelector('.header').style.paddingRight = 0;
    });
  }
  // cookies end

  // Show wrapper cite
  setTimeout(() => {
    document.querySelector('.header .header__wrap').classList.add('fadeDown');
  }, 100);

  // Leaflet map init
  initMap('map');
  // Leaflet map init end

  // Get & set footer, header height
  setVhProp();
  // Get & set footer, header height end

  // Animation init
  animation('[data-animation="fadeUp"]', 'fadeUp');
  animation('[data-animation="scaleUp"]', 'scaleIn');
  animation('[data-animation="fadeDown"]', 'fadeUp');
  // Animation init end

  // Modules calls ===========================================
  swiperInstance();
  // Modules calls end ===========================================

  // Anchor scroll
  const anchorBtns = document.querySelectorAll("a[href^='#']");
  anchorBtns.forEach((anchorBtn) => anchorBtn.addEventListener('click', anchorScrollHandler));
  // Anchor scroll end

  // Form validation
  const forms = document.querySelectorAll('form[data-validation]');
  const numbersInputs = document.querySelectorAll('input[data-validation-type="phone"]');

  numbersInputs.length &&
    numbersInputs.forEach((input) =>
      input.addEventListener('input', () => (input.value = input.value.replace(/[^0-9() +-]/g, '')))
    );

  forms.forEach((form) => {
    form.addEventListener('input', (e) => formHandler(e.target));
    form.addEventListener('submit', (e) => {
      let validStatus = true;
      const inputs = e.currentTarget.querySelectorAll('[data-need-validation="true"]');
      inputs.forEach((el) => {
        if (!formHandler(el)) validStatus = false;
      });
      if (!validStatus) e.preventDefault();
    });
  });
  // Form validation end

  // Simple bar
  const simpleBarElements = document.querySelectorAll('.simple-bar');

  simpleBarElements.forEach((el) => {
    new SimpleBar(el, { autoHide: false });
  });
  // Simple bar end

  // Header ===========================================
  // Navigation menu
  const burgerToggler = document.querySelector('.header__nav-trigger');
  const navigation = document.querySelector('.navigation.top');
  let burgerOpen = false;
  const closeBurger = () => {
    if (burgerOpen) {
      burgerToggler.classList.remove('active');
      navigation.classList.remove('active');
      document.body.classList.remove('body-lock');
      burgerOpen = false;
    }
  };

  burgerToggler &&
    navigation &&
    burgerToggler.addEventListener('click', () => {
      burgerToggler.classList.toggle('active');
      navigation.classList.toggle('active');
      document.body.classList.toggle('body-lock');
      burgerOpen = !burgerOpen;
    });
  // Header - Navigation menu end
  // Header end ===========================================

  // Home page ===========================================
  // Home page - banner
  const videoWrap = document.querySelector('.js-video-wrap');
  let videoIntervalId = null;

  if (videoWrap) {
    const video = videoWrap.querySelector('.js-banner-video');
    const canvasLeft = videoWrap.querySelector('#js-video-canvas-left');
    const canvasLeftCtx = canvasLeft.getContext('2d');
    const canvasRight = videoWrap.querySelector('#js-video-canvas-right');
    const canvasRightCtx = canvasRight.getContext('2d');

    const initInterval = () => {
      videoIntervalId = setInterval(() => {
        canvasLeftCtx.drawImage(video, 0, 0, canvasLeft.width, canvasLeft.height);

        canvasRightCtx.drawImage(video, 0, 0, canvasRight.width, canvasRight.height);
      }, 30);
    };

    const initCanvas = () => {
      canvasLeft.width = video.videoWidth;
      canvasLeft.height = video.videoHeight;
      canvasRight.width = video.videoWidth;
      canvasRight.height = video.videoHeight;

      clearInterval(videoIntervalId);
      initInterval();
    };

    setTimeout(() => {
      initCanvas();
      videoWrap.style.opacity = 1;
    }, 300);
  }
  // Home page - banner end

  // Home page - Service section
  const serviceTabBtns = document.querySelectorAll('.services-tab__btn');
  const serviceRows = document.querySelectorAll('.services-tab .services-tab__item');

  if (serviceTabBtns.length) {
    let tabletWindow = window.innerWidth <= 992;
    // const toggleActiveClass = (e) => {
    //   e.currentTarget.closest('.services-tab__item').classList.toggle('active');
    // };
    const hoverToggleClass = (event) => {
      serviceRows.forEach((element) => {
        if (event.currentTarget !== element) {
          element.classList.remove('active');
        } else {
          element.classList.add('active');
        }
      });
    };

    // tabletWindow && serviceTabBtns.forEach(btn => btn.addEventListener('click', toggleActiveClass));
    !tabletWindow && serviceRows.forEach((row) => row.addEventListener('mouseenter', hoverToggleClass));

    window.addEventListener('resize', () => {
      tabletWindow = window.innerWidth <= 992;

      // serviceTabBtns.forEach(btn => btn.removeEventListener('click', toggleActiveClass));
      serviceRows.forEach((row) => row.removeEventListener('mouseenter', hoverToggleClass));

      // if (tabletWindow) serviceTabBtns.forEach(btn => btn.addEventListener('click', toggleActiveClass));
      if (!tabletWindow) serviceRows.forEach((row) => row.addEventListener('mouseenter', hoverToggleClass));
    });
  }
  // Home page - Service section end

  // Home page - feedback cards video
  const videoBoxes = document.querySelectorAll('.testimonials__card-video-box');
  videoBoxes.forEach((videoBox) => {
    let videoPlaying = false;
    const btn = videoBox.querySelector('button'),
      video = videoBox.querySelector('video');
    btn &&
      btn.addEventListener('click', () => {
        if (!video) return;
        videoPlaying = !videoPlaying;
        btn.classList.toggle('active');
        videoPlaying ? video.play() : video.pause();
      });
  });
  // Home page - feedback cards video end
  // Home page end ===========================================
  // Single service page ===========================================
  // Single service page - setter table negative margin top
  const singleBannerWrap = document.querySelector('.single-services-banner__wrap');
  const singleOfferSection = document.querySelector('.single-service-offer');
  const singleOfferTableHeader =
    singleOfferSection && singleOfferSection.querySelector('.single-service-offer__table-header');
  const singleOfferSectionHandle = () => {
    singleBannerWrap.removeAttribute('style');
    const heightValue = getOffset(singleOfferTableHeader).rect.height;
    const bannerPaddingBottom = parseInt(window.getComputedStyle(singleBannerWrap).paddingBottom);

    singleOfferSection.style.marginTop = `-${heightValue}px`;
    singleBannerWrap.style.paddingBottom = `${bannerPaddingBottom + heightValue}px`;
  };
  singleOfferSection && singleOfferSectionHandle();

  // Single service page - setter table negative margin top end
  // Single service page end ===========================================

  const bannerVideo = document.querySelector('.home-banner__bg__video');
  let videoId = null;

  if (bannerVideo) {
    clearInterval(videoId);
    videoId = setInterval(() => {
      if (bannerVideo.currentTime >= 13.5 && bannerVideo.currentTime <= bannerVideo.duration) {
        bannerVideo.classList.add('hide');
      } else if (bannerVideo.currentTime >= 1 && bannerVideo.currentTime <= 13.5) {
        bannerVideo.classList.remove('hide');
      }
    }, 1000);
  }

  // Common func calls & listeners ===========================================
  // Scroll
  headerBackground();
  window.addEventListener('scroll', () => {
    headerBackground();
    setVhProp();
  });
  // Scroll end

  // Scroll
  window.addEventListener('resize', () => {
    singleOfferSection && singleOfferSectionHandle();
    closeBurger();
    setVhProp();
  });
  // Scroll end
  // Common func calls & listeners end ===========================================
});
