const addInvalidClass = (el, bool) => {
  if (bool) {
    el.classList.remove('invalid');
    el.classList.add('valid');
  } else {
    el.classList.remove('valid');
    el.classList.add('invalid');
  }
};
// const validateEmailReg = value => /\S+@\S+\.\S+/.test(value);

const mailValidate = input => {
  const mailValid = /\S+@\S+\.\S+/.test(input.value);
  addInvalidClass(input.closest('.form-group'), mailValid);
  return mailValid;
};

const phoneValidate = input => {
  const mailValid = input.value.length <= +input.maxLength && input.value.length > +input.minLength;
  addInvalidClass(input.closest('.form-group'), mailValid);
  return mailValid;
};

const emptyInputValidate = input => {
  const inputValid = input.value !== '';
  addInvalidClass(input.closest('.form-group'), inputValid);
  return inputValid;
};

const nameInputValidate = input => {
  const inputValid = input.value !== '';


  input.value = input.value.replace(/[^a-z A-Z а-я А-я Ї ї І і ]/g, "")

  addInvalidClass(input.closest('.form-group'), inputValid);
  return inputValid;
};

const checkboxValidate = input => {
  const { checked } = input;
  addInvalidClass(input.closest('.form-group'), checked);
  return checked;
};


const formHandler = input => {
  if (!JSON.parse(input.dataset.needValidation.toLowerCase())) return;

  switch (input.dataset.validationType) {
    case 'email' :
      return mailValidate(input);
    case 'phone' :
      return phoneValidate(input);
    case 'empty' :
      return emptyInputValidate(input);
    case 'name' :
      return nameInputValidate(input);
    case 'checkbox' :
      return checkboxValidate(input);
  }
};

export {
  formHandler
};
