import Swiper, { Navigation, Autoplay, EffectFade, Pagination } from 'swiper';

Swiper.use([Navigation, Autoplay, EffectFade, Pagination]);

const swiperInstance = () => {
  // Common full screen slider
  new Swiper('.js-fullscreen-slider', {
    slidesPerView: 1,
    pagination: {
      el: '.js-fullscreen-slider .slider__dots',
      bulletActiveClass: 'active',
      bulletClass: 'slider__dot dot-item',
      clickable: true
    }
  });

  // Slider testimonials
  // const setProgressWidth = ({ $el, activeIndex, slides, width }) => {
  //   const progressLine = $el[0].querySelector('.js-progress-bar .js-progress-bar-dynamic-line');
  //   const step = width / slides.length;
  //   progressLine.style.width = `${step * (activeIndex + 1)}px`;
  // };

  // const testimonialSliderOptions = {
  //   slidesPerView: 1,
  //   spaceBetween: 10,
  //   breakpoints: {
  //     670: {
  //       slidesPerView: 2
  //     },
  //     425: {
  //       slidesPerView: 1.2
  //     }
  //   }
  //   // on: {
  //   //   init: setProgressWidth,
  //   //   slideChange: setProgressWidth,
  //   //   resize: setProgressWidth
  //   // }
  // };

  // let testimonials = new Swiper('.js-slider-testimonials', testimonialSliderOptions);

  // window.addEventListener('resize', () => {
  //   if (testimonials && window.innerWidth >= 1025) {
  //     testimonials.destroy();
  //   } else if (window.innerWidth <= 1024) {
  //     testimonials = new Swiper('.js-slider-testimonials', testimonialSliderOptions);
  //   }
  // });

  // Slider clients
  new Swiper('.js-clients-slider', {
    speed: 750,
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    allowTouchMove: false,
    autoplay: {
      delay: 3000
    }
  });

  // Slider services
  const isDesktopWidth = window.matchMedia('only screen and (min-width: 992px)').matches;
  if (isDesktopWidth) {
    new Swiper('.js-slider-services', {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.js-slider-services .swiper-pagination ',
        clickable: true,
        renderBullet(index, className) {
          const services = ['Outstaff', 'Staff Augmentation', 'Relocation'];
          return `<span class='${className}'>${services[index]}</span>`;
        }
      }
    });
  }

  // Tabs slider
  new Swiper('.js-tabs-slider', {
    slidesPerView: 'auto',
    freeMode: true,
    breakpoints: {
      1025: {
        allowTouchMove: false
      }
    }
  });
};

export default swiperInstance;
