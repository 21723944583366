import L from 'leaflet';

const initMap = (id) => {
  if (document.getElementById(id)) {
    const map = L.map(id).setView([51.502298, -0.0187697, 16.67], 13);
    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {}).addTo(map);
    const marker = L.icon({
      iconUrl: './img/map-marker.png',

      iconSize: [45, 45]
    });
    L.marker([51.502298, -0.0187697, 16.67], { icon: marker }).addTo(map);
  }
};

export {
  initMap
};
